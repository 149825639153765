import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Paper, Grid, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import '../pages/BookingDetails.css';

const mapStyles = {
  height: '500px',
  width: '100%'
};

const defaultCenter = {
  lat: 6.9271,
  lng: 79.8612
};

const statuses = ['pending', 'ongoing', 'completed', 'cancelled'];

function BookingDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [driverPosition, setDriverPosition] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [editingStatus, setEditingStatus] = useState('');
  const [editingDriver, setEditingDriver] = useState('');
  const [nearbyVehicles, setNearbyVehicles] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    axios.get(`https://api.bayex.lk/taxi/api/admin/fetch_trip_details.php?id=${id}`)
      .then(response => {
        const { booking, driver_position } = response.data;
        setBooking(booking);
        setDriverPosition(driver_position);
        setEditingStatus(booking.status);
        setEditingDriver(booking.driver);

        if (booking.status === 'pending') {
          fetchNearbyVehicles(booking.pickup_latitude, booking.pickup_longitude);
        }
      })
      .catch(error => {
        console.error('Error fetching booking details:', error);
      });

    axios.get('https://api.bayex.lk/taxi/api/admin/drivers.php')
      .then(response => {
        setDrivers(response.data);
      })
      .catch(error => {
        console.error('Error fetching drivers:', error);
      });
  }, [id]);

  const fetchNearbyVehicles = (lat, lng) => {
    axios.get(`https://api.bayex.lk/taxi/api/admin/nearby_vehicles.php?lat=${lat}&lng=${lng}`)
      .then(response => {
        setNearbyVehicles(response.data);
      })
      .catch(error => {
        console.error('Error fetching nearby vehicles:', error);
      });
  };

  const handleSave = () => {
    axios.put(`https://api.bayex.lk/taxi/api/admin/update_trip_status.php`, {
      id: booking.id,
      status: editingStatus,
      driver: editingDriver,
    }).then(response => {
      console.log('Update successful', response);
    }).catch(error => {
      console.error('Error updating trip', error);
    });
  };

  if (!booking) {
    return <CircularProgress />;
  }

  const isValidCoordinate = (lat, lng) => typeof lat === 'number' && typeof lng === 'number';

  const isTripInProgress = booking.status !== 'pending' && booking.status !== 'cancelled';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, padding: 2 }}>
      <Button variant="outlined" onClick={() => navigate(-1)}>Back</Button>
      <Typography variant="h4" gutterBottom>Booking Details</Typography>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>User Name:</strong> {booking.user_name}</Typography>
            <Typography variant="body1"><strong>Vehicle Type:</strong> {booking.vehicle_type}</Typography>
            <Typography variant="body1"><strong>Status:</strong> 
              {booking.status === 'pending' ? (
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={editingStatus}
                    onChange={(e) => setEditingStatus(e.target.value)}
                  >
                    {statuses.map(status => (
                      <MenuItem key={status} value={status}>{status}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                booking.status
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Distance:</strong> {booking.distance} km</Typography>
            <Typography variant="body1"><strong>Estimated Time:</strong> {booking.estimated_time} mins</Typography>
            <Typography variant="body1"><strong>Total Cost:</strong> {booking.total_cost} LKR</Typography>
            <Typography variant="body1"><strong>Shedule:</strong> {booking.scheduled_date_time} </Typography>
            <Typography variant="body1"><strong>Booking:</strong> {booking.created_at} </Typography>
            <Typography variant="body1"><strong>Payment Method:</strong> {booking.payment_method}</Typography>
            <Typography variant="body1"><strong>Driver:</strong>
              {booking.status === 'pending' ? (
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel>Driver</InputLabel>
                  <Select
                    value={editingDriver}
                    onChange={(e) => setEditingDriver(e.target.value)}
                  >
                    {drivers.map(driver => (
                      <MenuItem key={driver.id} value={driver.id}>
                        {driver.driver_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                booking.driver_name || 'N/A'
              )}
            </Typography>
          </Grid>
        </Grid>
        {booking.status === 'pending' && (
          <Box sx={{ mt: 3 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Box>
        )}
      </Paper>
      <Box sx={{ height: '500px', marginTop: 3 }}>
        <Typography variant="h5" gutterBottom>Map View</Typography>
        <LoadScript googleMapsApiKey="AIzaSyBDa19FKk-mRQVgIzaHKA7-iXGbDeI3qv0">
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={{
              lat: booking.pickup_latitude || defaultCenter.lat,
              lng: booking.pickup_longitude || defaultCenter.lng
            }}
          >
            {isValidCoordinate(booking.pickup_latitude, booking.pickup_longitude) && (
              <Marker
                position={{ lat: booking.pickup_latitude, lng: booking.pickup_longitude }}
                onClick={() => setSelectedPlace({ lat: booking.pickup_latitude, lng: booking.pickup_longitude, type: 'Pickup Location' })}
              />
            )}
            {isValidCoordinate(booking.dropoff_latitude, booking.dropoff_longitude) && (
              <Marker
                position={{ lat: booking.dropoff_latitude, lng: booking.dropoff_longitude }}
                onClick={() => setSelectedPlace({ lat: booking.dropoff_latitude, lng: booking.dropoff_longitude, type: 'Dropoff Location' })}
              />
            )}
            {isTripInProgress && driverPosition && isValidCoordinate(driverPosition.vehicle_current_lat, driverPosition.vehicle_current_lng) && (
              <Marker
                position={{ lat: driverPosition.vehicle_current_lat, lng: driverPosition.vehicle_current_lng }}
                onClick={() => setSelectedPlace({ lat: driverPosition.vehicle_current_lat, lng: driverPosition.vehicle_current_lng, type: 'Driver Location' })}
              />
            )}
            {selectedPlace && (
              <InfoWindow
                position={{ lat: selectedPlace.lat, lng: selectedPlace.lng }}
                onCloseClick={() => setSelectedPlace(null)}
              >
                <div>{selectedPlace.type}</div>
              </InfoWindow>
            )}
            {booking.status === 'pending' && nearbyVehicles.map((vehicle, index) => (
              isValidCoordinate(vehicle.vehicle_current_lat, vehicle.vehicle_current_lng) && (
                <Marker
                  key={index}
                  position={{ lat: vehicle.vehicle_current_lat, lng: vehicle.vehicle_current_lng }}
                  onClick={() => setSelectedPlace({ lat: vehicle.vehicle_current_lat, lng: vehicle.vehicle_current_lng, type: 'Nearby Vehicle' })}
                />
              )
            ))}
          </GoogleMap>
        </LoadScript>
      </Box>
    </Box>
  );
}

export default BookingDetails;
