import React from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar, Typography, Box, Divider, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SettingsIcon from '@mui/icons-material/Settings';
import BookIcon from '@mui/icons-material/Book';

const drawerWidth = 240;

const menuItems = [
  // adminusers
  { text: 'Home', icon: <HomeIcon />, path: '/home' },
  { text: 'Users', icon: <PeopleIcon />, path: '/users' },
  { text: 'Drivers', icon: <DirectionsCarIcon />, path: '/drivers' },
  { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
  { text: 'Bookings', icon: <BookIcon />, path: '/bookings' },
  { text: 'Earnings', icon: <BookIcon />, path: '/earnings' },
  { text: 'Adminusers', icon: <BookIcon />, path: '/adminusers' },
];

function Sidebar({ mobileOpen, onDrawerToggle }) {
  const drawer = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Toolbar sx={{ bgcolor: 'primary.main', color: 'white', justifyContent: 'center' }}>
        <Typography variant="h6" noWrap>
          Admin Panel
        </Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ flexGrow: 1, overflow: 'auto', bgcolor: 'background.default', p: 1 }}>
        <List>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              component={Link}
              to={path}
              sx={{
                borderRadius: 1,
                '&:hover': {
                  bgcolor: 'primary.light',
                },
              }}
            >
              <ListItemIcon sx={{ color: 'primary.main' }}>
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  variant: 'body1',
                  fontWeight: 'medium',
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={{ p: 2, bgcolor: 'primary.main', color: 'white', textAlign: 'center' }}>
        <Typography variant="body2">
          © 2024 Developed by Lemda
        </Typography>
      </Box>
    </Box>
  );

  return (
    <nav aria-label="admin panel navigation">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={onDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            bgcolor: 'background.paper',
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            bgcolor: 'background.paper',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </nav>
  );
}

export default Sidebar;
