import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

function Users() {
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    address: '',
    password: '',
    profile_picture: '',
    referral_code: '',
    refered_code: '',
    otp: '',
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios.get('https://api.bayex.lk/taxi/api/admin/users.php')
      .then(response => setUsers(response.data))
      .catch(error => {
        console.error('Error fetching users:', error);
        enqueueSnackbar('Error fetching users.', { variant: 'error' });
      });
  };

  const handleDialogOpen = (user = null) => {
    setEditingUser(user);
    setNewUser(user || {
      name: '',
      email: '',
      phone: '',
      location: '',
      address: '',
      password: '',
      profile_picture: '',
      referral_code: '',
      refered_code: '',
      otp: '',
    });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setEditingUser(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (editingUser) {
      // Update user
      axios.put('https://api.bayex.lk/taxi/api/admin/users.php', newUser)
        .then(response => {
          fetchUsers();
          enqueueSnackbar('User updated successfully.', { variant: 'success' });
        })
        .catch(error => {
          console.error('Error updating user:', error);
          enqueueSnackbar('Error updating user.', { variant: 'error' });
        });
    } else {
      // Add new user
      axios.post('https://api.bayex.lk/taxi/api/admin/users.php', newUser)
        .then(response => {
          fetchUsers();
          enqueueSnackbar('User added successfully.', { variant: 'success' });
        })
        .catch(error => {
          console.error('Error adding user:', error);
          enqueueSnackbar('Error adding user.', { variant: 'error' });
        });
    }
    handleDialogClose();
  };

  const handleDelete = (id) => {
    axios.delete(`https://api.bayex.lk/taxi/api/admin/users.php?id=${id}`)
      .then(response => {
        fetchUsers();
        enqueueSnackbar('User deleted successfully.', { variant: 'success' });
      })
      .catch(error => {
        console.error('Error deleting user:', error);
        enqueueSnackbar('Error deleting user.', { variant: 'error' });
      });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Users Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleDialogOpen()}
        sx={{ marginBottom: 2 }}
      >
        Add User
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {['ID', 'Name', 'Email', 'Phone', 'Location', 'Address', 'Password', 'Profile Picture', 'Referral Code', 'Referred Code', 'OTP', 'Actions'].map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.location}</TableCell>
                <TableCell>{user.address}</TableCell>
                <TableCell>{user.password}</TableCell>
                <TableCell>
                  {user.profile_picture ? (
                    <a href={user.profile_picture} target="_blank" rel="noopener noreferrer">View</a>
                  ) : 'Not Uploaded'}
                </TableCell>
                <TableCell>{user.referral_code}</TableCell>
                <TableCell>{user.refered_code}</TableCell>
                <TableCell>{user.otp}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleDialogOpen(user)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(user.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>{editingUser ? 'Edit User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={newUser.name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={newUser.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Phone"
            name="phone"
            value={newUser.phone}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Location"
            name="location"
            value={newUser.location}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Address"
            name="address"
            value={newUser.address}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Password"
            name="password"
            type="password"
            value={newUser.password}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Profile Picture URL"
            name="profile_picture"
            value={newUser.profile_picture}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Referral Code"
            name="referral_code"
            value={newUser.referral_code}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Referred Code"
            name="refered_code"
            value={newUser.refered_code}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="OTP"
            name="otp"
            value={newUser.otp}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Users;
