import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [botCheckAnswer, setBotCheckAnswer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Simple math question for bot checking
  const [num1] = useState(Math.floor(Math.random() * 10));
  const [num2] = useState(Math.floor(Math.random() * 10));

  const handleLogin = async () => {
    // Check if the bot check answer is correct
    if (parseInt(botCheckAnswer) !== num1 + num2) {
      setErrorMessage('Bot check failed. Please try again.');
      return;
    }

    try {
      // Make a request to the backend for authentication
      const response = await axios.post('https://api.bayex.lk/taxi/api/admin/login.php', { username, password });
      if (response.data.success) {
        console.log(response.data);
        setAuthenticated(true);
        navigate('/home');
      } else {
        setErrorMessage('Invalid username or password.');
      }
    } catch (error) {
      setErrorMessage('An error occurred during login.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Typography variant="h4" gutterBottom>Admin Login</Typography>
      <TextField
        label="Username"
        variant="outlined"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <Typography variant="body1" gutterBottom>
        Solve the following: {num1} + {num2} = ?
      </Typography>
      <TextField
        label="Answer"
        variant="outlined"
        value={botCheckAnswer}
        onChange={(e) => setBotCheckAnswer(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      {errorMessage && <Typography color="error" gutterBottom>{errorMessage}</Typography>}
      <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
    </Box>
  );
};

export default Login;
