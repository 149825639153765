import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Modal,
  TextField,
  TablePagination,
  InputAdornment,
  Typography,Autocomplete
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEdit, faTrash, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';

// Custom icon for location marker
const locationIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  shadowSize: [41, 41],
});

function Bookings() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [drivers, setDrivers] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editingStatus, setEditingStatus] = useState('');
  const [editingDriverId, setEditingDriverId] = useState('');
  const [openMap, setOpenMap] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [mapPosition, setMapPosition] = useState(null);
  const [mapType, setMapType] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const previousPendingCountRef = useRef(0);

  // Load sound for new booking notification
  const newBookingSound = new Audio('https://api.bayex.lk/taxi/api/admin/ring.mp3');


  useEffect(() => {
    fetchBookings();
    fetchDrivers();
    // Start polling for new pending bookings
    const intervalId = setInterval(checkForNewPendingBookings, 5000); // Poll every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [page, rowsPerPage, searchTerm, sortOrder]);

  const fetchBookings = () => {
    axios.get(`https://api.bayex.lk/taxi/api/admin/bookings_pagination.php?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}&order=${sortOrder}`)
      .then(response => {
        if (response.data.bookings) {
          setBookings(response.data.bookings);
          setTotalCount(response.data.totalCount);
        } else {
          enqueueSnackbar('Unexpected response format.', { variant: 'error' });
        }
      })
      .catch(error => {
        console.error('Error fetching bookings:', error);
        enqueueSnackbar('Error fetching bookings.', { variant: 'error' });
      });
  };

  const fetchDrivers = () => {
    axios.get('https://api.bayex.lk/taxi/api/admin/drivers.php')
      .then(response => {
        if (Array.isArray(response.data)) {
          setDrivers(response.data);
        } else {
          enqueueSnackbar('Unexpected response format.', { variant: 'error' });
        }
      })
      .catch(error => {
        console.error('Error fetching drivers:', error);
        enqueueSnackbar('Error fetching drivers.', { variant: 'error' });
      });
  };

  const checkForNewPendingBookings = () => {
    axios.get('https://api.bayex.lk/taxi/api/admin/check_pending_bookings.php')
      .then(response => {
        if (response.data && response.data.pendingCount !== undefined) {
          const newPendingCount = response.data.pendingCount;

          if (newPendingCount > 0) {
            newBookingSound.play();
            enqueueSnackbar('New pending booking arrived!', { variant: 'info' });
            fetchBookings(); // Optionally refresh the booking list
          }

          previousPendingCountRef.current = newPendingCount;
        } else {
          enqueueSnackbar('Unexpected response format.', { variant: 'error' });
        }
      })
      .catch(error => {
        console.error('Error checking for new pending bookings:', error);
        enqueueSnackbar('Error checking for new pending bookings.', { variant: 'error' });
      });
  };

  const handleEditClick = (booking) => {
    setEditingId(booking.booking.id);
    setEditingStatus(booking.booking.status);
    setEditingDriverId(booking.booking.driver);
  };

  const handleStatusChange = (event) => {
    setEditingStatus(event.target.value);
  };

  const handleDriverChange = (event) => {
    setEditingDriverId(event.target.value);
  };

  const handleSaveClick = () => {
    axios.put('https://api.bayex.lk/taxi/api/admin/trip_bookings.php', {
      id: editingId,
      status: editingStatus,
      driver: editingDriverId,
    })
      .then(response => {
        if (response.data.message === 'Trip booking updated successfully') {
          fetchBookings();
          setEditingId(null);
          enqueueSnackbar('Booking updated successfully.', { variant: 'success' });
        } else {
          console.error('Error updating booking:', response.data);
          enqueueSnackbar('Error updating booking.', { variant: 'error' });
        }
      })
      .catch(error => {
        console.error('Error updating booking:', error);
        enqueueSnackbar('Error updating booking.', { variant: 'error' });
      });
  };

  const handleDeleteClick = (id) => {
    axios.delete(`https://api.bayex.lk/taxi/api/admin/trip_bookings.php?id=${id}`)
      .then(response => {
        if (response.data.message === 'Trip booking deleted successfully') {
          fetchBookings();
          enqueueSnackbar('Booking deleted successfully.', { variant: 'success' });
        } else {
          console.error('Error deleting booking:', response.data);
          enqueueSnackbar('Error deleting booking.', { variant: 'error' });
        }
      })
      .catch(error => {
        console.error('Error deleting booking:', error);
        enqueueSnackbar('Error deleting booking.', { variant: 'error' });
      });
  };

  const handleOpenMap = (booking, type) => {
    setSelectedBooking(booking);
    setMapPosition({
      lat: type === 'pickup' ? booking.booking.pickup_latitude : booking.booking.dropoff_latitude,
      lng: type === 'pickup' ? booking.booking.pickup_longitude : booking.booking.dropoff_longitude,
    });
    setMapType(type);
    setOpenMap(true);
  };

  const handleSaveLocation = () => {
    if (selectedBooking && mapPosition) {
      const data = {
        id: selectedBooking.booking.id,
        [mapType === 'pickup' ? 'pickup_latitude' : 'dropoff_latitude']: mapPosition.lat,
        [mapType === 'pickup' ? 'pickup_longitude' : 'dropoff_longitude']: mapPosition.lng,
      };
      axios.put('https://api.bayex.lk/taxi/api/admin/trip_bookings.php', data)
        .then(response => {
          if (response.data.message === 'Trip booking updated successfully') {
            fetchBookings();
            setOpenMap(false);
            enqueueSnackbar('Location updated successfully.', { variant: 'success' });
          } else {
            console.error('Error updating location:', response.data);
            enqueueSnackbar('Error updating location.', { variant: 'error' });
          }
        })
        .catch(error => {
          console.error('Error updating location:', error);
          enqueueSnackbar('Error updating location.', { variant: 'error' });
        });
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Trip Bookings Management</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          label="Search Bookings"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
          }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            label="Sort By"
          >
            <MenuItem value="desc">Descending</MenuItem>
            <MenuItem value="asc">Ascending</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Pickup Location</TableCell>
              <TableCell>Dropoff Location</TableCell>
              <TableCell>Vehicle Type</TableCell>
              <TableCell>Driver Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.map((booking) => (
              <TableRow key={booking.booking.id}>
                <TableCell>{booking.booking.id}</TableCell>
                <TableCell>{booking.name} - {booking.user_phone}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenMap(booking, 'pickup')}
                    startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                  >
                    View in Map
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenMap(booking, 'dropoff')}
                    startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                  >
                    View in Map
                  </Button>
                </TableCell>
                <TableCell>{booking.booking.vehicle_type}</TableCell>
                <TableCell>
  {editingId === booking.booking.id ? (
    <FormControl variant="outlined" size="small" fullWidth>
      <Autocomplete
        options={drivers}
        getOptionLabel={(option) => option.driver_name || ''}
        value={drivers.find(driver => driver.id === editingDriverId) || null}
        onChange={(event, newValue) => {
          setEditingDriverId(newValue ? newValue.id : '');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Driver"
            variant="outlined"
            size="small"
          />
        )}
      />
    </FormControl>
  ) : (
    <>
      {booking.driver_name || 'N/A'} - {booking.driver_phone}
    </>
  )}
</TableCell>
                <TableCell>
                  {editingId === booking.booking.id ? (
                    <FormControl variant="outlined" size="small">
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={editingStatus}
                        onChange={handleStatusChange}
                        label="Status"
                      >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="accepted">Accepted</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    booking.booking.status
                  )}
                </TableCell>
                <TableCell>
                  {editingId === booking.booking.id ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSaveClick}
                      startIcon={<FontAwesomeIcon icon={faEdit} />}
                    >
                      Save
                    </Button>
                  ) : (
                    <>
                      <IconButton onClick={() => navigate(`/booking/${booking.booking.id}`)}>
                        <FontAwesomeIcon icon={faEye} />
                      </IconButton>
                      <IconButton onClick={() => handleEditClick(booking)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </IconButton>
                      <IconButton color="error" onClick={() => handleDeleteClick(booking.booking.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableContainer>

      <Modal
        open={openMap}
        onClose={() => setOpenMap(false)}
        aria-labelledby="map-modal-title"
        aria-describedby="map-modal-description"
      >
        <Box sx={{ width: '80%', margin: 'auto', backgroundColor: 'white', padding: 2 }}>
          <Typography id="map-modal-title" variant="h6">
            {mapType === 'pickup' ? 'Pickup Location' : 'Dropoff Location'}
          </Typography>
          <MapContainer
            center={mapPosition || [51.505, -0.09]}
            zoom={13}
            style={{ height: '400px', width: '100%' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {mapPosition && (
              <Marker position={mapPosition} icon={locationIcon}>
                <Popup>
                  {mapType === 'pickup' ? 'Pickup Location' : 'Dropoff Location'}
                </Popup>
              </Marker>
            )}
          </MapContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button variant="contained" onClick={handleSaveLocation}>Save Location</Button>
            <Button variant="outlined" onClick={() => setOpenMap(false)}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Bookings;
