import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import ThreeWheelerIcon from '../assets/images/three_wheeler.png';
import MiniCarIcon from '../assets/images/mini_car.png';
import CarIcon from '../assets/images/car.png';
import flexible_car from '../assets/images/flexible_car.png';
import mini_van from '../assets/images/mini_van.png';
import special_car from '../assets/images/special_car_fixed.png';
import kdh from '../assets/images/kdh.png';
import qute from '../assets/images/qute.png';
import { Paper, Typography, Box, Select, MenuItem, FormControl, InputLabel, TextField, Grid, Card, CardContent } from '@mui/material';
import { FullscreenControl } from 'react-leaflet-fullscreen'; // Correct import for FullscreenControl
import 'react-leaflet-fullscreen/styles.css'; // Correct import for styles
import '../Home.css'; // Assuming you have a CSS file for styles

// Custom marker icons for each vehicle type
const vehicleIcons = {
  'Three Wheeler': new L.Icon({
    iconUrl: ThreeWheelerIcon,
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  }),
  'Flexible Car': new L.Icon({
    iconUrl: flexible_car,
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  }),
  'Mini Van': new L.Icon({
    iconUrl: mini_van,
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  }),
  'Special Car': new L.Icon({
    iconUrl: special_car,
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  }),
  'KDH': new L.Icon({
    iconUrl: kdh,
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  }),
  'Bajaj Qute': new L.Icon({
    iconUrl: qute,
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  }),
  'Mini Car': new L.Icon({
    iconUrl: MiniCarIcon,
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  }),
  'Car': new L.Icon({
    iconUrl: CarIcon,
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  }),
};

function Home() {
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState('All');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [statistics, setStatistics] = useState({
    totalDrivers: 0,
    activeDrivers: 0,
    inactiveDrivers: 0,
    tripsToday: 0,
  });

  useEffect(() => {
    fetchStatistics();
    fetchDrivers();
  }, []);

  const fetchStatistics = () => {
    axios.get('https://api.bayex.lk/taxi/api/admin/fetch_statistics.php')
      .then(response => {
        setStatistics(response.data);
      })
      .catch(error => console.error('Error fetching statistics:', error));
  };

  const fetchDrivers = () => {
    axios.get('https://api.bayex.lk/taxi/api/admin/drivers_locations.php')
      .then(response => {
        setDrivers(response.data);
        setFilteredDrivers(response.data);
      })
      .catch(error => console.error('Error fetching drivers:', error));
  };

  useEffect(() => {
    if (selectedVehicleType === 'All') {
      setFilteredDrivers(drivers);
    } else {
      setFilteredDrivers(drivers.filter(driver => driver.vehicle_type === selectedVehicleType));
    }
  }, [selectedVehicleType, drivers]);

  const handleDateChange = (type, value) => {
    if (type === 'start') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Statistics</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card sx={{ backgroundColor: '#e3f2fd' }}>
                  <CardContent>
                    <Typography variant="h5">{statistics.totalDrivers}</Typography>
                    <Typography variant="subtitle1">Total Drivers</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ backgroundColor: '#e8f5e9' }}>
                  <CardContent>
                    <Typography variant="h5">{statistics.activeDrivers}</Typography>
                    <Typography variant="subtitle1">Active Drivers</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ backgroundColor: '#ffebee' }}>
                  <CardContent>
                    <Typography variant="h5">{statistics.inactiveDrivers}</Typography>
                    <Typography variant="subtitle1">Inactive Drivers</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ backgroundColor: '#fff3e0' }}>
                  <CardContent>
                    <Typography variant="h5">{statistics.tripsToday}</Typography>
                    <Typography variant="subtitle1">Trips Today</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Filter by Date</Typography>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={startDate}
              onChange={(e) => handleDateChange('start', e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={endDate}
              onChange={(e) => handleDateChange('end', e.target.value)}
              sx={{ mb: 2 }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Filter by Vehicle Type</InputLabel>
              <Select
                value={selectedVehicleType}
                onChange={(e) => setSelectedVehicleType(e.target.value)}
                label="Filter by Vehicle Type"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Three Wheeler">Three Wheeler</MenuItem>
                <MenuItem value="Bajaj Qute">Bajaj Qute</MenuItem>
                <MenuItem value="Mini Car">Mini Car</MenuItem>
                <MenuItem value="Car">Car</MenuItem>
                <MenuItem value="Flexible Car">Flexible Car</MenuItem>
                <MenuItem value="Special Car">Special Car</MenuItem>
                <MenuItem value="Mini Van">Mini Van</MenuItem>
                <MenuItem value="KDH">KDH</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          <Paper elevation={3}>
            <MapContainer center={[7.8731, 80.7718]} zoom={8} style={{ height: '600px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <FullscreenControl position="topright" /> {/* Add FullscreenControl here */}
              {filteredDrivers.map(driver => {
                const lat = parseFloat(driver.latitude);
                const lng = parseFloat(driver.longitude);

                if (!isNaN(lat) && !isNaN(lng) && lat !== 0 && lng !== 0) {
                  return (
                    <Marker
                      key={driver.id}
                      position={[lat, lng]}
                      icon={vehicleIcons[driver.vehicle_type] || vehicleIcons['Car']}
                    >
                      <Popup className="custom-popup">
                        <div className="popup-content">
                          <strong>{driver.driver_name}</strong><br />
                          <span>Vehicle: {driver.vehicle_number}</span><br />
                          <span>Phone: {driver.phone}</span><br />
                        </div>
                      </Popup>
                    </Marker>
                  );
                } else {
                  console.warn(`Invalid coordinates for driver ${driver.driver_name}: ${lat}, ${lng}`);
                  return null;
                }
              })}
            </MapContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
