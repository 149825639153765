import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

function AdminUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const [adminUsers, setAdminUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  const fetchAdminUsers = () => {
    axios.get('https://api.bayex.lk/taxi/api/admin/admin_user_management.php?action=fetch')
      .then(response => setAdminUsers(response.data))
      .catch(error => {
        console.error('Error fetching admin users:', error);
        enqueueSnackbar('Error fetching admin users.', { variant: 'error' });
      });
  };

  const handleDialogOpen = (user = null) => {
    setEditingUser(user);
    setNewUser(user || { username: '', password: '' });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setEditingUser(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    const url = editingUser
      ? 'https://api.bayex.lk/taxi/api/admin/admin_user_management.php?action=update'
      : 'https://api.bayex.lk/taxi/api/admin/admin_user_management.php?action=create';

    const formData = new FormData();
    Object.keys(newUser).forEach(key => {
      if (newUser[key] !== null) {
        formData.append(key, newUser[key]);
      }
    });

    axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(response => {
        fetchAdminUsers();
        enqueueSnackbar(`Admin user ${editingUser ? 'updated' : 'added'} successfully.`, { variant: 'success' });
        handleDialogClose();
      })
      .catch(error => {
        console.error(`Error ${editingUser ? 'updating' : 'adding'} admin user:`, error);
        enqueueSnackbar(`Error ${editingUser ? 'updating' : 'adding'} admin user.`, { variant: 'error' });
      });
  };

  const handleDelete = (id) => {
    axios.get(`https://api.bayex.lk/taxi/api/admin/admin_user_management.php?action=delete&id=${id}`)
      .then(response => {
        fetchAdminUsers();
        enqueueSnackbar('Admin user deleted successfully.', { variant: 'success' });
      })
      .catch(error => {
        console.error('Error deleting admin user:', error);
        enqueueSnackbar('Error deleting admin user.', { variant: 'error' });
      });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Admin Users Management</h1>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => handleDialogOpen()}
        >
          Add Admin User
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminUsers.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDialogOpen(user)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(user.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{editingUser ? 'Edit Admin User' : 'Add Admin User'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Username"
            name="username"
            value={newUser.username}
            onChange={handleChange}
            fullWidth
          />
          {!editingUser && (
            <TextField
              margin="dense"
              label="Password"
              name="password"
              type="password"
              value={newUser.password}
              onChange={handleChange}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AdminUsers;
